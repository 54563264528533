<template>
  <div>
    <v-card flat>
      <v-card-text>
        <div v-if="!loading">
          <v-data-iterator :items="items" hide-default-footer :page="page">
            <template v-slot:header v-if="role !== 2">
              <v-toolbar class="mb-2" color="indigo darken-5" dark flat>
                <v-toolbar-items>
                  <v-btn to="/connections/create">Создать новое</v-btn>
                </v-toolbar-items>
                <v-toolbar-title
                  >Заяления на технологическое присоединение</v-toolbar-title
                >
                <v-spacer />
              </v-toolbar>
            </template>
            <template v-slot:default="props">
              <v-list>
                <v-list-item
                  v-for="item in props.items"
                  :key="item._id"
                  dense
                  link
                  active-class="blue--text"
                  style="margin: 5px; background-color: #E1F5FE"
                  @click="contextShow(item, $event)"
                >
                  <v-list-item-avatar>
                    <v-icon class="green lighten-4" dark>
                      mdi-file
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-title>
                    {{ item.partner.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ STATUS[item.status] }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Вх. №{{ item.enterNum }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.createdAt | date('datetime') }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </template>
          </v-data-iterator>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="6"
            ></v-pagination>
          </div>
          <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
          >
            <v-list>
              <v-list-item link :to="'/connection/' + ctxItem._id">
                <v-list-item-title>Открыть</v-list-item-title>
              </v-list-item>
              <v-list-item link v-if="role !== 2" :to="'/connections/' + ctxItem._id + '/edit'">
                <v-list-item-title>Редактировать</v-list-item-title>
              </v-list-item>
              <v-list-item link v-if="role >= 5">
                <v-list-item-title>Удалить</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-skeleton-loader class="mx-auto" type="article"></v-skeleton-loader>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { CONNECTION_STATUS } from '../../../utils/mainConsts';
import { sendRequest } from '../../../utils/graphql';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ConnectionsList',
  data: () => ({
    page: 1,
    items: [],
    ctxItem: { _id: 0 },
    showMenu: false,
    loading: true,
    STATUS: CONNECTION_STATUS,
    x: 0,
    y: 0,
    ctxMenuItems: [
      { title: 'Открыть' },
      { title: 'Редактировать' },
      { title: 'Удалить' }
    ]
  }),
  computed: {
    role() {
      return this.$store.state.role;
    },
    ...mapState({ universal: state => state.univarsal })
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    ...mapMutations(['sendRequest']),
    contextShow(item, e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.ctxItem = item;
        this.showMenu = true;
      });
    },
    async fetchAll() {
      const token = this.$store.state.token;
      const query = `query ConnectionFetchAll {
          connectionFetchAll {
            ${this.universal.defaultDataToFetch.connection}
          }
        }`;
      const variables = {};

      this.loading = true;
      const result = await sendRequest(token, query, variables);
      if (result.errors) {
        const errorText = result.errors[0].data.reduce((summ, curr) => {
          return summ + curr.message + ', ';
        }, '');
        const message = `Ошибка: ${errorText}`;
        this.setMessageData({
          message,
          type: 'snackbar'
        });
      } else {
        // Получен результат
        this.loading = false;
        this.items = result.data.connectionFetchAll;
      }
    }
  }
};
</script>

<style></style>
